.loginPage {
  background: url("../../assets/img_easy/fullScreenDialog_bg.jpg") no-repeat center/cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .loginPage__close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
  }
  .loginPage__form {
    max-width: 500px;
    margin: auto;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    padding: 100px 20px 20px 20px;
    position: relative;
    .loginPage__title {
      position: absolute;
      top: -60px;
      left: 50%;
      width: 95%;
      transform: translateX(-50%);
      background: #2c99a6;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 120px;
      font-size: 24px;
      font-weight: 700;
      color: #fff;
    }
    .loginPage__formik {
      .loginPage__formikInner {
        display: flex;
        flex-direction: column;
        .loginPage__input {
          display: flex;
          align-items: flex-end;
          .loginPage__label {
            min-width: 100px;
            padding-bottom: 5px;
            font-size: 14px;
            color: #929292;
          }
        }
        .loginPage__btn {
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 700;
          color: #00acc1 !important;
          margin: 20px 0;
        }
        .forgot {
          cursor: pointer;
          text-align: center;
          font-size: 14px;
          color: #006ce5;
          text-decoration: underline;
        }
      }
    }
    &_recovery {
      .loginPage__title {
        background: linear-gradient(to bottom, #300363, #ff8cff 100%);
      }
      .loginPage__formik {
        .loginPage__formikInner {
          .loginPage__btn {
            color: #6334e3 !important;
          }
        }
      }
    }
  }
}
